







































































































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import { GearIcon, Tooltip, InfoCircleIcon } from '@nimiq/vue-components';
import AnnouncementBox from '../AnnouncementBox.vue';
import AccountMenu from '../AccountMenu.vue';
import PriceChart, { TimeRange } from '../PriceChart.vue';
import BalanceDistribution from '../BalanceDistribution.vue';
import ConsensusIcon from '../ConsensusIcon.vue';
import StreetconeIcon from '../icons/StreetconeIcon.vue';
import AttentionDot from '../AttentionDot.vue';

import { useAddressStore } from '../../stores/Address';
import { useBtcAddressStore } from '../../stores/BtcAddress';
import { useUsdcAddressStore } from '../../stores/UsdcAddress';
import { useSettingsStore } from '../../stores/Settings';
import { useAccountStore, AccountType } from '../../stores/Account';
import { useSwapsStore } from '../../stores/Swaps';
import { useConfig } from '../../composables/useConfig';
import { useWindowSize } from '../../composables/useWindowSize';
import { i18n } from '../../i18n/i18n-setup';
import { ENV_TEST, ENV_DEV, CryptoCurrency } from '../../lib/Constants';

export default defineComponent({
    setup(props, context) {
        const { config } = useConfig();
        const { isMobile, height: windowHeight } = useWindowSize();

        async function navigateTo(path: string) {
            if (isMobile.value) {
                return context.root.$router.replace(path);
            }
            return context.root.$router.push(path).catch(() => { /* ignore */ });
        }

        async function openModal(routeName: string, params: Record<string, string> = {}) {
            // Each modal is expected to be sitting above a specific parent route / background page. If we're not
            // currently on that route, navigate to it first, such that the modal can be closed later by a simple back
            // navigation leading to that parent route. If we wouldn't do that, a back navigation would lead back to our
            // current route, but with the modal still open on top.
            const modalRoute = context.root.$router.resolve({ name: routeName }).route;
            const expectedParentRoute = modalRoute.matched.find(({ name }) => !!name && name !== routeName);
            if (expectedParentRoute && context.root.$route.name !== expectedParentRoute.name) {
                // Don't keep the sidebar open for this navigation on mobile because closing it would be a back
                // navigation on the parent page, leading back to the route we're currently on, instead of closing the
                // sidebar.
                await navigateTo(expectedParentRoute.path);
            }
            return context.root.$router.push({
                name: routeName,
                query: { sidebar: 'true' }, // on mobile keep sidebar open in background
                params,
            }).catch(() => { /* ignore */ });
        }

        // Note: config.environment should never change at runtime.
        const isTestnet = config.environment === ENV_TEST || config.environment === ENV_DEV;
        const isDev = config.environment === ENV_DEV;

        const priceChartTimeRange = ref(TimeRange['24h']);
        function switchPriceChartTimeRange() {
            if (priceChartTimeRange.value === TimeRange['24h']) {
                priceChartTimeRange.value = TimeRange['7d'];
            } else {
                priceChartTimeRange.value = TimeRange['24h'];
            }
        }

        const { addressInfos, selectAddress } = useAddressStore();
        function resetState() {
            navigateTo('/');

            if (addressInfos.value.length > 0) {
                selectAddress(addressInfos.value[0].address);
            }
        }

        const { updateAvailable, canUseSwaps } = useSettingsStore();

        const { activeAccountInfo, hasBitcoinAddresses, hasUsdcAddresses } = useAccountStore();
        const isLegacyAccount = computed(() => activeAccountInfo.value?.type === AccountType.LEGACY);

        const activatedCurrencies = computed(() => [
            CryptoCurrency.NIM, // NIM is always activated
            ...(config.enableBitcoin && hasBitcoinAddresses.value ? [CryptoCurrency.BTC] : []),
            ...(config.usdc.enabled && hasUsdcAddresses.value ? [CryptoCurrency.USDC] : []),
        ]);

        const hasSwappableBalance = computed(() => [useAddressStore, useBtcAddressStore, useUsdcAddressStore]
            .some((useStore) => {
                const store = useStore();
                // For USDC, only native USDC is supported for swapping.
                return 'nativeAccountBalance' in store ? store.nativeAccountBalance.value : store.accountBalance.value;
            }),
        );

        const { activeSwap } = useSwapsStore();
        const hasActiveSwap = computed(() => activeSwap.value !== null);

        // The Sidebar's buttons have mousedown.prevent to circumvent focus issues on iOS. As this also causes focused
        // tooltips to not lose focus and close if such a button is clicked, we close them manually.
        function hideTooltips() {
            for (const refComponent of Object.values(context.refs)) {
                if (!(refComponent instanceof Tooltip)) continue;
                refComponent.hide();
            }
        }

        const { activeCurrency } = useAccountStore();

        const canSellCryptoWithMoonpay = computed(() => activeCurrency.value !== CryptoCurrency.NIM);

        const nimSellOptions = computed(() => {
            const [intro, optionSwap, optionExchange] = (i18n.t('You can sell NIM\n'
                + '- in the Wallet by swapping NIM to BTC or USDC first, which can then be sold.\n'
                + '- on supported exchanges.') as string).split(/\s*-\s*/);
            // return options only if translators translated them correctly and kept the format
            return intro && optionSwap && optionExchange
                ? { intro, optionSwap, optionExchange }
                : null;
        });

        return {
            navigateTo,
            resetState,
            isTestnet,
            isDev,
            windowHeight,
            priceChartTimeRange,
            switchPriceChartTimeRange,
            isLegacyAccount,
            activatedCurrencies,
            hasSwappableBalance,
            hasActiveSwap,
            canUseSwaps,
            updateAvailable,
            hideTooltips,
            openModal,
            canSellCryptoWithMoonpay,
            nimSellOptions,
        };
    },
    components: {
        AnnouncementBox,
        GearIcon,
        PriceChart,
        BalanceDistribution,
        AccountMenu,
        ConsensusIcon,
        Tooltip,
        InfoCircleIcon,
        StreetconeIcon,
        AttentionDot,
    },
});
